<template>
  <div class="QuotationItem" :style="isPdf ? { width: '90%' } : ''">
    <a-tooltip placement="topLeft" v-if="showToolTip">
      <template slot="title">
        <span
          ><template v-if="showUnit">{{ currencyUnit }}</template
          >{{ text }}
        </span>
      </template>
      <div class="desc" :style="width">
        <template v-if="showUnit">{{ currencyUnit }}</template
        >{{ text }}
      </div>
    </a-tooltip>
    <div :style="width" class="desc" v-else ref="content">
      <template v-if="showUnit">{{ currencyUnit }}</template
      >{{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'isPdf', 'showUnit', 'currencyUnit'],
  data() {
    return {
      showToolTip: false,
    }
  },
  mounted() {
    this.isShowToolTip()
  },
  computed: {
    width() {
      return this.isPdf ? { width: '100%' } : { width: '60px' }
    },
  },
  watch: {
    text() {
      this.$nextTick(() => {
        this.isShowToolTip()
      })
    },
  },
  methods: {
    isShowToolTip() {
      try {
        const el = this.$refs.content
        this.showToolTip = el.clientWidth < el.scrollWidth
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
</style>
